<template>
  <ul class="category-tab" ref="categoryTab">
    <li v-for="(item, index) in tabs"
        :key="index"
        ref="liRefs"
        :class="{active: index === modelValue}"
        @click="moveSlider(index)">
        <slot :item="item" :index="index">
          {{ item }}
        </slot>
    </li>
  </ul>
  <div class="slide-block" v-if="!disableSlide" :style="{...sliderStyle}"></div>

</template>

<script setup>
const { tabs, modelValue, transform, disableSlide } = defineProps({
  tabs: {
    type: Array,
    required: true
  },
  modelValue: {
    type: Number,
    required: true,
    default: 0
  },
  transform: {
    type: Boolean,
    default: true
  },
  disableSlide: {
    type: Boolean,
    default: true
  }
});

const liRefs = ref([]); // tabs 实例
const emit = defineEmits(['update:modelValue']);
const sliderStyle = ref({ width: 0, left: 0 }); // 切换选项卡样式

const moveSlider = (index) => {

  if (index === -1) {
    sliderStyle.value = {
      opacity: 0
    }
    return;
  }

  const liRect = liRefs.value[index];
  if (!liRect) return;
  sliderStyle.value = {
    opacity: 1,
    width: `${liRect?.offsetWidth}px`,
    transform: `translate3d(${liRect?.offsetLeft}px, 0, 0) scaleX(${transform ? '1.6' : '1'})`,
    transformOrigin: modelValue > index ? 'left' : 'right' // 设置 transform-origin 为左侧或右侧
  };

  if (transform) {
    setTimeout(() => {
      sliderStyle.value.transform = `translate3d(${liRect.offsetLeft}px, 0, 0) scaleX(1)`;
    }, 250);
  }

  emit('update:modelValue', index); // 更新父组件的 activeIndex
};
if (process.client) {
  onMounted(async ()=> {
    await nextTick()
    moveSlider(modelValue);
  })
  watchPostEffect(() => {
    moveSlider(modelValue); // 在 DOM 更新完成后执行
  });
}


</script>

<style scoped lang="scss">
.category-tab {
  @include flex-y-center;
  overflow: hidden;
  color: var(--tab-color);
  height: 100%;
  .active {
    color: var(--primary-color);
  }
  li {
    user-select: none;
    cursor: pointer;
    text-align: center;
    margin-left: var(--active-li-ml);
    a {
      max-width: 100%; /* 确保宽度随内容调整 */
    }
  }
  li:first-child {
    margin-left: 0;
  }
}
.slide-block {
  position: absolute;
  bottom: 0;
  width: 52px;
  height: 4px;
  transition: transform .5s ease;
  will-change: transform; /* 提高性能 */
  background-color: var(--primary-color);
}
</style>
